<template>
  <div>
    <AppNav v-if="appView">링고라 수업가이드</AppNav>
    <main class="main" :class="{ 'is-app': appView }">
      <section class="section-1">
        <div class="text-img">
          <img class="img" :src="`${IMG_URL}guide_main.png`" alt="HOW TO USE TUTORING" />
          <div class="text-wrapper">
            <strong class="guide-title">
              차별화된 1:1
              <br />
              영어 학습 링고라
            </strong>
            <div class="desc-wrapper">
              <p class="guide-desc">
                링고라(Lingora)는
                <strong class="colored-title">
                  휴먼 튜터링과 AI의 첨단 기술을 바탕으로 사용자에게 맞춤형 외국어 학습 솔루션
                </strong>

                을 제공합니다. 학습 스타일과 목표에 맞춰 가장 적합한 학습 방법을 선택할 수 있으며, 유연하고 경제적인
                옵션을 통해 높은 학습 성과를 달성할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="part">
          <strong class="title">
            국내 유일!
            <br />
            예약 없이 바로 수업
          </strong>
          <div class="desc-wrapper">
            <p class="desc">
              매 수업 시간, 튜터, 토픽을 직접 선택해
              <br />
              <span class="emphasis">예약 or 예약 없이 바로 수업</span>
              <br />
              자유로운 수업을 경험해 보세요!
            </p>
          </div>
        </div>
        <div class="text-img">
          <img class="lesson" :src="`${IMG_URL}guide_01.png`" alt="lesson-img" />
        </div>
      </section>
      <section class="section-3">
        <div class="part">
          <strong class="title">
            나에게 맞는
            <br />
            수업 방식 선택
          </strong>
        </div>
        <div class="img-wrapper cards-wrapper">
          <div class="cards">
            <img
              class="all-lesson"
              :src="`${IMG_URL}card_intro_class_01.png`"
              alt="card_intro_class_01"
              aria-hidden="true"
            />
            <img
              class="voice-lesson"
              :src="`${IMG_URL}card_intro_class_02.png`"
              alt="card_intro_class_02"
              aria-hidden="true"
            />
            <img
              class="ai-lesson"
              :src="`${IMG_URL}card_intro_class_03.png`"
              alt="card_intro_class_03"
              aria-hidden="true"
            />
            <img
              class="ai-quiz"
              :src="`${IMG_URL}card_intro_class_04.png`"
              alt="card_intro_class_04"
              aria-hidden="true"
            />
          </div>
        </div>

        <div class="section">
          <div class="part">
            <div class="title-wrapper">
              <strong class="title">AI 학습 오픈!</strong>
              <div class="tag">
                <strong class="title">Beta</strong>
              </div>
            </div>
            <p class="desc">
              원어민 1:1 회화와 AI 학습까지 부담 없는 실력 향상!
              <br />
              베타 오픈 기간동안 AI 학습 무료로 이용하실 수 있습니다.
            </p>

            <div class="video-box">
              <video
                class="video"
                playsInline
                autoplay
                controls
                muted
                src="//res.tutoring.co.kr/res/images/web/b2b/lingoraAI_Demo.mp4"
              />
            </div>
          </div>
        </div>

        <div class="part-check">
          <strong class="title">
            업계최저가 보장!
            <br />
            상설특가제 실시
          </strong>

          <p class="desc">
            최고 수준의 서비스를 업계 최저가로
            <br />
            제공하기 위한 상설특가제 실시!
          </p>
          <div class="check">
            <p class="check-decs">정가대비 50% 이상 상시 할인</p>
            <p class="check-decs">경쟁사 서비스 가격 모니터링 및 비교</p>
            <p class="check-decs">
              최저가 신고보상제로
              <span class="emphasis">차액 최대 10배</span>
              보상
            </p>
          </div>
        </div>
        <div class="img-wrapper">
          <img class="price" :src="`${IMG_URL}guide_02.png`" alt="price" aria-hidden="true" />
        </div>
        <div class="part">
          <div class="explain-box">
            <p class="desc">
              상설특가제 페이지에서 가격 비교 기준 및 경쟁사별 가격 비교 상세 내용을 확인하실 수 있습니다.
            </p>
          </div>
        </div>
        <div class="section">
          <div class="part">
            <strong class="title">
              신규 가입 시
              <br />
              원어민과 AI 학습 무료 혜택
            </strong>
            <p class="desc">
              가입만 하면 누구나
              <br />
              <span class="emphasis">원어민 1:1 회화 수업 1회 무료 제공</span>
              <br />
              <span class="emphasis">AI 매일 5분 무료</span>
            </p>
          </div>

          <div class="img-wrapper">
            <img class="coupon" :src="`${IMG_URL}guide_03.png`" alt="coupon" aria-hidden="true" />
          </div>
        </div>
        <div class="section">
          <div class="part-consulting">
            <strong class="title">
              회원이라면 누구나
              <br />
              학습 컨설팅 무료
            </strong>
            <p class="desc">
              학습법부터 수강권 추천, 동기 부여까지
              <br />
              링고라 고객이라면 누구나
              <br />
              학습 전문가 컨설팅 무료 제공!
            </p>
          </div>
          <div class="img-wrapper">
            <img class="consult-view" :src="`${IMG_URL}guide_04.png`" alt="consult-view" aria-hidden="true" />
          </div>
          <div class="part-box">
            <div class="explain-box">
              <strong>학습 상담 신청 방법</strong>
              <p class="desc">마이링고라 > 학습 전문 컨설턴트</p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="footer">
      <strong class="title">
        이제 링고라와 공부하고
        <br />
        새로운 세상을 만나볼까요?
      </strong>
      <div class="img-wrapper">
        <img class="tudal" :src="`${IMG_URL}guide_tudal.png`" alt="tudal-img" aria-hidden="true" />
      </div>
      <div class="btn-wrapper">
        <button class="main-btn" @click="goToService()">링고라 시작하기</button>
      </div>
      <div class="share">
        <p class="text">친구에게 링고라를 소개해 보세요!</p>
        <button class="btn kakao" @click="sendKakaoMsg()">
          <img :src="`${IMG_URL}ic_kakao.svg`" alt="카카오톡으로 공유하기" />
        </button>
      </div>
    </footer>
    <PopupToast :isPopup="isPopup"><p v-html="popUpMessage"></p></PopupToast>
  </div>
</template>

<script lang="ts">
import { native, tracker } from '@/tools';
import inappTools from '@/common/inappTools';
import AppNav from '../../components/AppNav.vue';
import PopupToast from '../../components/PopupToast.vue';

export default {
  name: 'PageGuideLesson',
  components: {
    AppNav,
    PopupToast,
  },
  data() {
    return {
      isPopup: false,
      popUpMessage: '페이지 링크가 복사되었습니다.',
      IMG_URL: 'https://res.tutoring.co.kr/res/images/v2/guide/',
    };
  },

  computed: {
    appView(): boolean {
      return inappTools.isInApp();
    },
    parcedHost(): string {
      return window.location.host.replace('app.', '').replace('-', '.');
    },
  },

  mounted() {
    document.addEventListener('scroll', this.loadKaKao);
  },

  methods: {
    loadKaKao() {
      const tag = document.createElement('script');
      const firstScriptTag = document.getElementsByTagName('script')[0];
      tag.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
      (firstScriptTag.parentNode as HTMLElement).insertBefore(tag, firstScriptTag);
      tag.addEventListener('load', () => {
        window.Kakao.init('35c9ab2e8b13eef66747d5e0a2e8bfb5');
      });
      document.removeEventListener('scroll', this.loadKaKao);

      return () => {
        tag.remove();
      };
    },
    goToService(): void {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('Lesson_guideLesson_lesson');
        native.close();
      } else {
        tracker.recordUserAction('Web_guideLesson_store');
        window.location.href = 'https://tutoring.co.kr/v2/';
      }
    },
    sendKakaoMsg(): void {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('Lesson_guideLesson_kakao');
      } else {
        tracker.recordUserAction('Web_guideLesson_kakao');
      }
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: '튜터링 수업 가이드',
          description: '더 나은 서비스 사용을 위해, 튜터링을 차근차근 들여다 볼까요?',
          imageUrl: 'https://res.tutoring.co.kr/res/images/img/guide/img_kakao_guidemsg.png',
          link: {
            mobileWebUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
            webUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
              webUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
            },
          },
          {
            title: '앱으로 보기',
            link: {
              mobileWebUrl:
                'https://tutoring.co.kr/p?utm_source=kakao&utm_medium=&utm_campaign=&utm_term=&dp=tutoring%3A%2F%2Fapp&ol=https%3A%2F%2Ftutoringapp.onelink.me%2FKVJp%3Fpid%3Dkakao%26c%3D%26af_adset%3D%26af_ad%3D%26af_click_lookback%3D30d%26af_dp%3Dtutoring%253A%252F%252Fapp',
              webUrl:
                'https://tutoring.co.kr/p?utm_source=kakao&utm_medium=&utm_campaign=&utm_term=&dp=tutoring%3A%2F%2Fapp&ol=https%3A%2F%2Ftutoringapp.onelink.me%2FKVJp%3Fpid%3Dkakao%26c%3D%26af_adset%3D%26af_ad%3D%26af_click_lookback%3D30d%26af_dp%3Dtutoring%253A%252F%252Fapp',
            },
          },
        ],
      });
    },
    sendLink(): void {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('Lesson_guideLesson_share');
      } else {
        tracker.recordUserAction('Web_guideLesson_share');
      }
      if (navigator.share) {
        navigator.share({
          title: '튜터링 수업 가이드',
          url: `https://${window.location.host}/v2/pages/guide/lesson`,
        });
      } else {
        const url = window.document.location.href;
        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        textarea.value = url;
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.copyUrl();
      }
    },
    copyUrl(): void {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
  },

  directives: {
    lazy: {
      mounted(el: HTMLVideoElement) {
        const videoLoad = async (targetElement: HTMLVideoElement) => {
          const videoElement = targetElement;
          await videoElement.setAttribute('src', videoElement.getAttribute('lazy-src') as string);
          await videoElement.removeAttribute('lazy-src');
        };

        const lazyLoad = () => {
          const options = {
            root: null,
            threshold: 0.2,
            rootMargin: '0px',
          };

          const lazyLoadCallback = (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                videoLoad(entry.target);
                observer.unobserve(entry.target);
              }
            });
          };

          const lazyLoadingIO = new IntersectionObserver(lazyLoadCallback, options);
          lazyLoadingIO.observe(el);
        };

        if ('IntersectionObserver' in window) {
          lazyLoad();
        } else {
          videoLoad(el);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
$iconUrl: '//res.tutoring.co.kr/res/images/tps/icon/';
$imgUrl: '//res.tutoring.co.kr/res/images/img/guide/';

@mixin tablet($minimum_width: 768px) {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop($minimum_width: 1025px) {
  @media (min-width: 1025px) {
    @content;
  }
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.main {
  width: 100%;
  overflow: hidden;
  &.is-app {
    padding-top: 42px;
  }
}

.section {
  padding: 48px 0;
  background-color: #ffffff;

  .text-img {
    margin: 0px auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video {
    max-width: 100%;
    margin-top: 32px;
    border-radius: 16px;
    aspect-ratio: 16 / 9;
  }
  .img-wrapper {
    margin-top: 32px;
  }
  .desc-wrapper {
    margin-top: 8px;
  }
}

.part {
  max-width: 360px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.title {
  color: #121214;
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  @include tablet {
    max-width: 360px;
  }
}

.desc {
  color: rgba(18, 18, 20, 0.6);
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @include tablet {
    max-width: 360px;
  }
  .emphasis {
    color: #06bf9d;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}

.title-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tag {
  background-color: #662ddf;
  min-height: 26px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 10000px;

  .title {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }
}

.explain-box {
  padding: 16px;
  border: 1px solid #662ddf;
  border-radius: 16px;
  color: #662ddf;

  .desc {
    color: #662ddf;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.video-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-1 {
  position: relative;
  padding: 48px 0;
  background-color: #1e073f;
  overflow: hidden;
  .text-img {
    max-width: 360px;
    margin: 0px auto;
    .img {
      width: 100%;
    }
  }
  .text-wrapper {
    text-align: center;
    padding-top: 48px;
    color: #ffffff;

    .guide-title {
      font-size: 32px;
      font-weight: 700;
      line-height: 45px;
    }
    .desc-wrapper {
      text-align: left;
      padding: 24px 40px 0 40px;
      .colored-title {
        color: #30e3c2;
        font-weight: 700;
      }
    }
    .guide-desc {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-break: keep-all;
      text-align: center;
    }
  }
}

.section-2 {
  .part {
    max-width: 360px;
    margin: 0 auto 32px auto;
    display: flex;
    flex-direction: column;

    .text-img {
      padding: 0 10px 0 10px;
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      overflow: hidden;
    }
    .desc-wrapper {
      margin: 8px 0 32px 0;
    }
    .section-2-desc {
      color: rgba(18, 18, 20, 0.6);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      .colored-title {
        color: #06bf9d;
        font-weight: 700;
      }
    }
  }
}

.section-3 {
  .part {
    max-width: 360px;
    margin: 0 auto 48px auto;
    display: flex;
    flex-direction: column;
  }
  .part-check {
    max-width: 360px;
    margin: 0 auto 32px auto;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }

  .part-consulting {
    max-width: 360px;
    margin: 0 auto 16px auto;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }
  .part-box {
    max-width: 360px;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }
  .img-wrapper {
    overflow: scroll;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    img {
      width: 360px;
    }
    .all-lesson,
    .voice-lesson,
    .ai-lesson,
    .ai-quiz {
      width: 280px;
      margin-right: 16px;
    }
    .cards {
      display: flex;
      justify-content: start;
      margin-bottom: 48px;
    }
    @media (max-width: 918px) {
      margin: 0 auto;
      overflow: scroll;

      .cards {
        padding: 0 40px;
      }
      &.cards-wrapper {
        justify-content: flex-start;
      }
    }
  }

  .desc {
    margin: 8px 0;
    word-break: keep-all;
  }
  .check {
    padding-left: 20px;
  }
  .check-decs {
    color: rgba(18, 18, 20, 0.6);
    margin-bottom: 13px;
    position: relative;
    height: 11px;
    justify-content: center;
    margin-right: 4px;

    &::after {
      display: block;
      position: absolute;
      left: -6%;
      top: 32%;
      width: 7px;
      height: 4px;
      border: 2px solid #06bf9d;
      border-top: 0;
      border-right: 0;
      transform: rotate(-45deg);
      content: '';
    }
    .emphasis {
      color: #06bf9d;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
}

.ico {
  width: 24px;
  height: 24px;
  display: block;
  background-size: 100%;
  font-size: 0;
  &--close {
    background-image: url($iconUrl + 'ic_close_outlined_gray900_24px.png');
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px 0;
  background-color: #f1f0f5;
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    color: #121214;
  }

  .text {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #5e5a66;
    opacity: 0.6;
  }

  .tudal {
    margin: 32px 0;
    width: 160px;
  }

  .btn-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px;
    margin-bottom: 30px;
    max-width: 300px;
  }

  .main-btn {
    width: 100%;
    height: 56px;
    background: #5d04d9;
    border-radius: 10px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .share {
    text-align: center;
    .text {
      margin-bottom: 32px;
    }
    .btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      background-color: #ffffff;
      border-radius: 100%;
      & + .btn {
        margin-left: 10px;
      }
    }
  }
}
</style>
