import { render } from "./PageGuideLesson.vue?vue&type=template&id=20896494&scoped=true"
import script from "./PageGuideLesson.vue?vue&type=script&lang=ts"
export * from "./PageGuideLesson.vue?vue&type=script&lang=ts"

import "./PageGuideLesson.vue?vue&type=style&index=0&id=20896494&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-20896494"
/* hot reload */
if (module.hot) {
  script.__hmrId = "20896494"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('20896494', script)) {
    api.reload('20896494', script)
  }
  
  module.hot.accept("./PageGuideLesson.vue?vue&type=template&id=20896494&scoped=true", () => {
    api.rerender('20896494', render)
  })

}

script.__file = "src/page/PageGuideLesson/PageGuideLesson.vue"

export default script