
import { native, tracker } from '@/tools';
import inappTools from '@/common/inappTools';
import AppNav from '../../components/AppNav.vue';
import PopupToast from '../../components/PopupToast.vue';

export default {
  name: 'PageGuideLesson',
  components: {
    AppNav,
    PopupToast,
  },
  data() {
    return {
      isPopup: false,
      popUpMessage: '페이지 링크가 복사되었습니다.',
      IMG_URL: 'https://res.tutoring.co.kr/res/images/v2/guide/',
    };
  },

  computed: {
    appView(): boolean {
      return inappTools.isInApp();
    },
    parcedHost(): string {
      return window.location.host.replace('app.', '').replace('-', '.');
    },
  },

  mounted() {
    document.addEventListener('scroll', this.loadKaKao);
  },

  methods: {
    loadKaKao() {
      const tag = document.createElement('script');
      const firstScriptTag = document.getElementsByTagName('script')[0];
      tag.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
      (firstScriptTag.parentNode as HTMLElement).insertBefore(tag, firstScriptTag);
      tag.addEventListener('load', () => {
        window.Kakao.init('35c9ab2e8b13eef66747d5e0a2e8bfb5');
      });
      document.removeEventListener('scroll', this.loadKaKao);

      return () => {
        tag.remove();
      };
    },
    goToService(): void {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('Lesson_guideLesson_lesson');
        native.close();
      } else {
        tracker.recordUserAction('Web_guideLesson_store');
        window.location.href = 'https://tutoring.co.kr/v2/';
      }
    },
    sendKakaoMsg(): void {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('Lesson_guideLesson_kakao');
      } else {
        tracker.recordUserAction('Web_guideLesson_kakao');
      }
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: '튜터링 수업 가이드',
          description: '더 나은 서비스 사용을 위해, 튜터링을 차근차근 들여다 볼까요?',
          imageUrl: 'https://res.tutoring.co.kr/res/images/img/guide/img_kakao_guidemsg.png',
          link: {
            mobileWebUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
            webUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
              webUrl: `https://${this.parcedHost}/v2/pages/guide/lesson`,
            },
          },
          {
            title: '앱으로 보기',
            link: {
              mobileWebUrl:
                'https://tutoring.co.kr/p?utm_source=kakao&utm_medium=&utm_campaign=&utm_term=&dp=tutoring%3A%2F%2Fapp&ol=https%3A%2F%2Ftutoringapp.onelink.me%2FKVJp%3Fpid%3Dkakao%26c%3D%26af_adset%3D%26af_ad%3D%26af_click_lookback%3D30d%26af_dp%3Dtutoring%253A%252F%252Fapp',
              webUrl:
                'https://tutoring.co.kr/p?utm_source=kakao&utm_medium=&utm_campaign=&utm_term=&dp=tutoring%3A%2F%2Fapp&ol=https%3A%2F%2Ftutoringapp.onelink.me%2FKVJp%3Fpid%3Dkakao%26c%3D%26af_adset%3D%26af_ad%3D%26af_click_lookback%3D30d%26af_dp%3Dtutoring%253A%252F%252Fapp',
            },
          },
        ],
      });
    },
    sendLink(): void {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('Lesson_guideLesson_share');
      } else {
        tracker.recordUserAction('Web_guideLesson_share');
      }
      if (navigator.share) {
        navigator.share({
          title: '튜터링 수업 가이드',
          url: `https://${window.location.host}/v2/pages/guide/lesson`,
        });
      } else {
        const url = window.document.location.href;
        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        textarea.value = url;
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.copyUrl();
      }
    },
    copyUrl(): void {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
  },

  directives: {
    lazy: {
      mounted(el: HTMLVideoElement) {
        const videoLoad = async (targetElement: HTMLVideoElement) => {
          const videoElement = targetElement;
          await videoElement.setAttribute('src', videoElement.getAttribute('lazy-src') as string);
          await videoElement.removeAttribute('lazy-src');
        };

        const lazyLoad = () => {
          const options = {
            root: null,
            threshold: 0.2,
            rootMargin: '0px',
          };

          const lazyLoadCallback = (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                videoLoad(entry.target);
                observer.unobserve(entry.target);
              }
            });
          };

          const lazyLoadingIO = new IntersectionObserver(lazyLoadCallback, options);
          lazyLoadingIO.observe(el);
        };

        if ('IntersectionObserver' in window) {
          lazyLoad();
        } else {
          videoLoad(el);
        }
      },
    },
  },
};
